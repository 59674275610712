import React from "react"
import LoadableArea from "../../page-components/genericHero/LoadableArea"

const Hero = ({ appStoreLink, playStoreLink, signupLink, hero }) => {
  return (
    <LoadableArea
      hero={hero}
      appStoreLink={appStoreLink}
      playStoreLink={playStoreLink}
      signupLink={signupLink}
    />
  )
}

export default Hero
