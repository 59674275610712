import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/page-components/genericHero"
import InfoPanel from "../components/page-components/infoPanel"
import Features from "../components/page-components/features"
import Pricing from "../components/pricing/index"
import * as queryString from "query-string"

import {
  LOGIN_LINK,
  SIGN_UP_LINK,
  APP_STORE_LINK,
  PLAY_STORE_LINK,
} from "../constants/linkConstants"

const Feature = ({ data, location }) => {
  console.log(data)
  const {
    feature: {
      seo: { titleseo, description },
      infoPanel,
      hero,
      slug,
    },
    otherFeatures,
  } = data

  const searchParams = queryString.parse(location.search)
  const { utm_campaign, utm_medium, utm_source } = searchParams

  let signupLink = SIGN_UP_LINK
  let loginLink = LOGIN_LINK
  let appStoreLink = APP_STORE_LINK
  let playStoreLink = PLAY_STORE_LINK

  if (utm_campaign && utm_medium && utm_source) {
    signupLink = `${SIGN_UP_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
    loginLink = `${LOGIN_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
    appStoreLink = `${APP_STORE_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
    playStoreLink = `${PLAY_STORE_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
  }

  return (
    <>
      <SEO title={titleseo} description={description} />
      <Layout>
        <Hero
          hero={hero}
          signupLink={signupLink}
          appStoreLink={appStoreLink}
          playStoreLink={playStoreLink}
        />
        {infoPanel && infoPanel.map(panel => <InfoPanel panel={panel} />)}
        {otherFeatures.nodes && (
          <Features
            alternativeTitle="More Features"
            features={otherFeatures.nodes}
          />
        )}
        <Pricing signupLink={signupLink} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetSingleFeature($slug: String) {
    feature: strapiFeature(slug: { eq: $slug }) {
      slug
      clickable
      seo {
        titleseo
        description
      }
      infoPanel {
        subheader
        blurb
        background
        alignment
        imageAlt
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100, cropFocus: NORTH) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      hero {
        buttonText
        subtitle
        title
        imageAlt
        heroImage {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100, cropFocus: NORTH) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    otherFeatures: allStrapiFeature(filter: { slug: { ne: $slug } }) {
      nodes {
        slug
        clickable
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subheader
        description
        order
        imageAlt
      }
    }
  }
`

export default Feature
