import React from "react"
import { Container, Row } from "react-grid-system"
import FullWidthSection from "../../layout/fullWidthSection"
import InnerContentContainer from "../../layout/innerContentContainer"
import Column from "../../layout/column"

import LoadingPlaceHolder from "../../loading/loadingPlaceholder"

import loadable from "@loadable/component"

const LoadableArea = loadable(() => import("./loadableArea"))

const Features = ({ features, alternativeTitle }) => {
  return (
    <FullWidthSection anchorName="features">
      <InnerContentContainer>
        <Row>
          <Column style={{ textAlign: "center", paddingBottom: "1rem" }}>
            <h2>{alternativeTitle || "Features"}</h2>
          </Column>
        </Row>

        <article className="features">
          <Container style={{ flex: 1 }}>
            <LoadableArea
              fallback={<LoadingPlaceHolder />}
              features={features}
            />
          </Container>
        </article>
      </InnerContentContainer>
    </FullWidthSection>
  )
}

export default Features
