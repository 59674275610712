import React from "react"
import { Container, Row } from "react-grid-system"
import FullWidthSection from "../../layout/fullWidthSection"
import InnerContentContainer from "../../layout/innerContentContainer"
import Column from "../../layout/column"
import Markdown from "react-markdown"
import Image from "gatsby-image"

import {
  defaultRenderersDarkBackground,
  defaultRenderers,
} from "../../../constants/mdxRenderers"

const getRenderers = background => {
  if (background === "dark") {
    return defaultRenderersDarkBackground
  }
  return defaultRenderers
}

const PanelImage = ({ image, alt }) => (
  <Column sm={12} md={12} lg={6}>
    <div>
      <Image
        imgStyle={{
          maxHeight: 500,
          objectFit: "contain",
        }}
        style={{
          margin: "0px auto",
          paddingBottom: "90%",
        }}
        fadeIn={false}
        loading={"eager"}
        fluid={image.childImageSharp.fluid}
        alt={alt}
      />
    </div>
  </Column>
)

const PanelText = ({ panel, headerClass, components }) => (
  <Column sm={12} md={12} lg={6}>
    <h3 className={headerClass}>{panel.subheader}</h3>
    <Markdown children={panel.blurb} components={components} />
  </Column>
)

const LeftLayout = ({ headerClass, panel, components }) => (
  <Row>
    <PanelText
      headerClass={headerClass}
      panel={panel}
      components={components}
    />
    <PanelImage alt={panel.imageAlt} image={panel.image} />
  </Row>
)

const RightLayout = ({ headerClass, panel, components }) => (
  <Row>
    <PanelImage alt={panel.imageAlt} image={panel.image} />
    <PanelText
      headerClass={headerClass}
      panel={panel}
      components={components}
    />
  </Row>
)

const InfoPanel = ({ panel }) => {
  const components = getRenderers(panel.background)
  const headerClass = panel.background === "dark" ? "white-text" : ""
  return (
    <FullWidthSection
      blue={panel.background === "dark"}
      lightGrey={panel.background === "lightGrey"}
    >
      <InnerContentContainer>
        <Container style={{ flex: 1 }}>
          {panel.alignment === "right" ? (
            <RightLayout
              headerClass={headerClass}
              panel={panel}
              components={components}
            />
          ) : (
            <LeftLayout
              headerClass={headerClass}
              panel={panel}
              components={components}
            />
          )}
        </Container>
      </InnerContentContainer>
    </FullWidthSection>
  )
}

export default InfoPanel
